import * as React from "react";
import { DefaultLayout } from "../layouts/DefaultLayout";

const HomePage = () => {
  return (
    <main>
      <title>Spaceraider</title>
      <div className="h-full w-full bg-blue-800 text-center text-4xl">...</div>
    </main>
  );
};

HomePage.Layout = DefaultLayout;
export default HomePage;
